import React from "react"
import tw, { styled } from "twin.macro"

const VideoContainer = styled.div`
  ${tw`
        w-full
        h-full
    `}
`

const Iframe = styled.iframe`
  width: 100%;
  height: 200px;
  @media (min-width: 768px) {
    height: 360px;
    width: 480px;
  }
`

interface VideoProps {
  videoSrcURL: string
  videoTitle: string
}

const YouTubeWrapper = styled.div`
  background-color: #000;
  /* margin-bottom: 30px; */
  position: relative;
  /* padding-top: 56.25%; */
  overflow: hidden;
  cursor: pointer;

  width: 100%;
  height: 200px;

  @media (min-width: 768px) {
    height: 360px;
    width: 480px;
  }
  &:hover {
    .play-button {
      background-color: #ff0000;
    }
  }
  img {
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0.7;
    cursor: pointer;
  }
  .play-button {
    width: 90px;
    height: 60px;
    background-color: #282828;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.6);
    z-index: 1;
    opacity: 0.8;
    border-radius: 6px;
    cursor: pointer;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }

  .play-button:before {
    content: "";
    border-style: solid;
    border-width: 15px 0 15px 26px;
    border-color: transparent transparent transparent #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
`

const Video: React.FC<VideoProps> = ({ videoSrcURL, videoTitle }) => {
  const [isClicked, setIsClicked] = React.useState(false)
  const vidRef = React.useRef(null)

  const handlePlay = () => {
    setIsClicked(true)
  }

  return (
    <VideoContainer>
      {isClicked ? (
        <Iframe
          ref={vidRef}
          src={`${videoSrcURL}?autoplay=1`}
          title={videoTitle}
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          frameBorder="0"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen
        />
      ) : (
        <YouTubeWrapper onClick={handlePlay}>
          <img
            src="https://img.youtube.com/vi/BxKJpZrXY3E/hqdefault.jpg"
            alt="Play Video"
            loading="lazy"
            width="480"
            height="360"
          />
          <button className="play-button"></button>
        </YouTubeWrapper>
      )}
    </VideoContainer>
  )
}

export default Video
